import React from "react";
import { useState, useEffect } from "react";
// import Brands from './Brands';
// import { Link } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import { RotatingLines } from  'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import DataTable from 'react-data-table-component';
// import $ from 'jquery';

const Hotel = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
    const [brand, setBrand] = useState('');

    const columns = [
        {
            name: 'Image',
            selector: row => row.images,
        },
        {
            name: 'Brand',
            selector: row => row.brand,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Address',
            selector: row => row.address,
        },
        {
            name: 'Map',
            selector: row => row.map,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Description',
            selector: row => row.description,
        },
        {
            name: 'Gallery',
            selector: row => row.gallery,
        },
        {
            name: 'See More',
            selector: row => row.seemore,
        },
        {
            name: 'Rating',
            selector: row => row.rating,
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
        },
    ];

	const fetchUsers = (brand) => {
		setLoading(true);

        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        const data = {
            name : brand
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => setData(data.results))
        .then(data => console.log(data))
        
		//setData(response.results);
		setTotalRows(8);
		setLoading(false);



		// const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);
        // console.log(response)
		// setData(response.data);
		// setTotalRows(response.total);
		// setLoading(false);
	};

	const handlePageChange = page => {
		fetchUsers(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

		const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);

		setData(response.data);
		setPerPage(newPerPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchUsers(''); // fetch page 1 of users
		
	}, []);

	return (
		<DataTable
			title="Users"
			columns={columns}
			data={data}
			progressPending={loading}
			pagination
			paginationServer
			paginationTotalRows={totalRows}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
		/>
	);
}



export default Hotel;