import React, { useState } from 'react';
import Brands from './Brands';
import Ratings from './Ratings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
    const [hname, setName] = useState('');
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [brand, setBrand] = useState('');
    const [gallery, setGallery] = useState('');
    const [seemore, setSeeMore] = useState('');
    const [rating, setRating] = useState()
    const [address, setAddress] = useState('')
    const [map, setMap] = useState('')
       
    const reset = () => {
        setDescription('');
        setName('');
        setPhone('');
        setGallery('');
        setSeeMore('');
        setRating('')
        setAddress('')
        setMap('')
    }

    const handleAddress = event => {
        const address = event.target.value;
        setAddress(event.target.value);
        if(address.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleBrand = event => {
        const brand = event.target.value;
        setBrand(event.target.value);
        if(brand.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleRating = event => {
        const rating = event.target.value;
        setRating(event.target.value);
        if(rating.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            description : description,
            name : hname,
            phone : phone,
            brand : brand,
            gallery : gallery,
            seemore : seemore,
            rating : rating,
            address : address,
            map : map
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    const bold = {
        fontWeight : '600'
    }

    const handleName = event => {
        const hname = event.target.value;
        setName(hname);
            
        if(hname.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handlePhone = event => {
        const phone = event.target.value;
        setPhone(phone);
            
        if(phone.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleDescription = event => {
        const desc = event.target.value;
        setDescription(desc);
            
        if(desc.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleMap = (event) => {
		setMap(event.target.value)
		if (event.target.value.indexOf('http') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
	}

    const handleGallery = (event) => {
		setGallery(event.target.value)
		if (event.target.value.indexOf('https://gallery.mystartcdn.com/') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
	}

    const handleSeeMore = (event) => {
		setSeeMore(event.target.value)
		if (event.target.value.indexOf('http') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
	}

   
    return ( 
        <div className="container-fluid">
            <ToastContainer />
            <form style={{ marginTop:'30px' }}>

                <div className="form-group mb-3">
                    <label style={bold}>Brands<span className="text-danger">*</span></label>
                    <div className="controls">
                        <select
                            name="brand"
                            id="brand"
                            className="form-select mb-3" 
                            onChange={handleBrand} 
                            value={brand}>
                                <option value="">Select the brand</option>
                                {
                                    Brands.map((item, i) => (
                                        <option value={item} key={i}>
                                                {item}
                                        </option>
                                    ))
                                }
                        </select>
                        <div className="invalid-feedback">The brand field is required </div>
                        <div className="valid-feedback"></div>
                    </div>
                </div>                   

                <div className="form-group mb-3">
                    <label style={bold}>Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="hname" value={hname} onChange={handleName}/>
                    <div className="invalid-feedback">The name field is required</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>Address <span className="text-danger">*</span></label>
                    <textarea rows={2} className="form-control small" value={address} onChange={handleAddress}></textarea>
                    <div className="invalid-feedback">The Address field is required (Min 10 characters)</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>Map Url <span className="text-danger">*</span></label>
                    <textarea rows={2} className="form-control small" value={map} onChange={handleMap}></textarea>
                    <div className="invalid-feedback">The Address field is required (Min 10 characters)</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>Description <span className="text-danger">*</span></label>
                    <textarea rows={2} className="form-control small" value={description} onChange={handleDescription}></textarea>
                    <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>Phone <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="phone" value={phone} onChange={handlePhone}/>
                    <div className="invalid-feedback">The phone field is required (Min 10 characters)</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>Gallery <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="gallery" value={gallery} onChange={handleGallery}/>
                    <div className="invalid-feedback">Provide with a valid gallery Url</div>
                    <div className="valid-feedback"></div>
                </div>

                <div className="form-group mb-3">
                    <label style={bold}>See More <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="seemore" value={seemore} onChange={handleSeeMore}/>
                    <div className="invalid-feedback">Provide with a valid See More Url</div>
                    <div className="valid-feedback"></div>
                </div>

                {
                brand === 'hotel' ?

                <div className="form-group mb-3">
                <label style={bold}>Stars</label>
                <div className="controls">
                    <select
                        name="rating"
                        id="rating"
                        className="form-select mb-3" 
                        onChange={handleRating} 
                        value={rating}>
                            <option value="">Select the stars</option>
                            {
                                Ratings.map((item, i) => (
                                    <option value={item} key={i}>
                                            {item}
                                    </option>
                                ))
                            }
                    </select>
                    <div className="invalid-feedback">The stars field is required </div>
                    <div className="valid-feedback"></div>
                </div>
            </div> 
            : ''
            }
                <div className="row justify-content-center">
                    <div className="col-md-1 col-md-offset-1">
                        <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                    </div>
                </div>
            </form>
        </div>
     );
}
 
export default Form;