import React from "react";
import Form from "../components/Form";
import Header from "../components/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const  Add = () => {
    return (
        <>
        <Header page="add"/> 
        <Form />
        </>
     );
}
 
export default Add;