import { useState } from "react";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleUsername = (e) => {
        setUsername(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const showError = {
        display : (errorMessage !== '') ? "block" : "none"
    }
    const box = {
        border:'1px solid #ccc', 
        borderRadius:'10px', 
        padding:'20px', 
        margin:'20px',
    }

    const getLogin = async () => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/login.php';
        setLoading(true)
        const response = await fetch(endpoint, {
            "method" : "POST",
            "body" : JSON.stringify({username:username, password:password})
        })
        const result = await response.json();
        if(result.action === 'failed'){
            setErrorMessage(result.msg)
            setLoading(false)
        }else{
            const user = {
                username : result.user.username,
                token: result.user.token
            }
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(user)); 
            window.location.replace("/");
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        getLogin();
    }

    return ( 
        <div className="container-login" style={{ maxWidth:'500px' }}>
            <div style={box}>
                <form>
                    <h3>Sign In</h3>
                    <div className="row" style={{ fontSize:'12px' }}>
                        <div className="
                                col
                                bg-danger
                                text-white
                                border-0
                                fade
                                show
                                " 
                                role="alert"
                                style={showError}
                                >
                                <strong>Error - </strong>{errorMessage}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" className="form-control" placeholder="Enter username" onChange={handleUsername}/>
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" onChange={handlePassword}/>
                    </div>
                    <br/>
                    {
                    loading ? <div className="loading">Loading...</div>
                    :
                    <button type="submit" style={{width:'100%'}} className="btn btn-primary btn-block" onClick={handleSubmit}>
                    Submit
                    </button>
                    }
                </form>
            </div>
        </div>
    );
}
 
export default Login;