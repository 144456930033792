import React from 'react'
import Header from '../components/Header'
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const QA = () => {
    const [breed, setBreed] = useState('');
    const [breeds, setBreeds] = useState([]);
    const [question, setQuestion] = useState('');
    const [allQuestions, setAllQuestions] = useState([]);
    const [response, setResponse] = useState('');
    const [allResponses, setAllResponses] = useState([]);
    const [source, setSource] = useState('');
    const [allSources, setAllSources] = useState([]);
    const [link, setLink] = useState('');
    const [allLinks, setAllLinks] = useState([]);

    const bold = {
        fontWeight : 'bold'
    }
    const getBreeds = async () => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/getBreeds.php';
        const response = await fetch(endpoint, {
            method : "POST",
            body : JSON.stringify({})
        });

        const data = await response.json()
        setBreeds(data)
    }
    const handleQuestion = event => {
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
            setQuestion(event.target.value);
        }
    };

    const handleResponse = event => {
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
            setResponse(event.target.value);
        }
    };

    const handleSource = event => {
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
            setSource(event.target.value);
        }
    };

    const handleLink = event => {
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
            setLink(event.target.value);
        }
    };

    const handleAddNewQuestion = () => {
       
        if(typeof question !== 'undefined' && question.length > 0){
            setAllQuestions([...allQuestions, question.replace(/[\n\r]+/g, '')]);
            setQuestion('');
        }else{
            return false;
        }
    }

    const handleAddNewResponse = () => {
        if(typeof response !== 'undefined' && response.length > 0){
            setAllResponses([...allResponses, response.replace(/[\n\r]+/g, '')]);
            setResponse('');
        }else{
            return false;
        }
    }

    const handleAddNewSource = () => {
        if(typeof source !== 'undefined' && source.length > 0){
            setAllSources([...allSources, source.replace(/[\n\r]+/g, '')]);
            setSource('');
        }else{
            return false;
        }
    }

    const handleAddNewLink = () => {
        if(typeof link !== 'undefined' && link.length > 0){
            setAllLinks([...allLinks, link.replace(/[\n\r]+/g, '')]);
            setLink('');
        }else{
            return false;
        }
    }

    const deleteSource = (i) => {
        allSources.splice(i, 1);
        setAllSources([...allSources]);
    }

    const deleteLink = (i) => {
        allLinks.splice(i, 1);
        setAllLinks([...allLinks]);
    }

    const deleteQuestion = (i) => {
        allQuestions.splice(i, 1);
        setAllQuestions([...allQuestions]);
    }

    const deleteResponse = (i) => {
        allResponses.splice(i, 1);
        setAllResponses([...allResponses]);
    }

    const handleBreed = event => {
        const breed = event.target.value;
        setBreed(event.target.value);
        if(breed.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            id : breed,
            allQuestions : allQuestions,
            allResponses : allResponses,
            allSources : allSources,
            allLinks : allLinks,
        }

        const endpoint = process.env.REACT_APP_BASE_URL + '/api/v2/addQA.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getBreeds()
    }, []);

  return (
    <>
        <ToastContainer /> 
        <Header page="QA"/>
        <form style={{ margin:'30px 10px'}}>
            <div className="row">
                <div className="col">
                    <div className="form-group mb-3">
                        <label style={bold}>Dog Breeds <span className="text-danger">*</span></label>
                        <div className="controls">
                            
                            <select
                                name="breed"
                                id="breed"
                                className="form-select mb-3" 
                                onChange={handleBreed} 
                                value={breed}>
                                    <option value="">Select the dog breed</option>
                                    {
                                        breeds.map((item, i) => (
                                            <option value={item.id} key={i}>
                                                    {item.name}
                                            </option>
                                        ))
                                    }
                            </select>
                            <div className="invalid-feedback">The breed field is required </div>
                            <div className="valid-feedback"></div>
                        </div>
                    </div>
                </div>
            </div>
            {
                breed !== '' ? 
                <>
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Question {allQuestions.length + 1}</label>
                                    <textarea className="form-control" rows="1" value={question} onChange={handleQuestion}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addQuestion" onClick={handleAddNewQuestion} className="btn btn-secondary">Add question {allQuestions.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allQuestions.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteQuestion(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Answer {allResponses.length + 1}</label>
                                    <textarea className="form-control" rows="1" value={response} onChange={handleResponse}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addResponse" onClick={handleAddNewResponse} className="btn btn-secondary">Add answer {allResponses.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allResponses.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteResponse(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Source {allSources.length + 1}</label>
                                    <textarea className="form-control" rows="1" value={source} onChange={handleSource}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addSource" onClick={handleAddNewSource} className="btn btn-secondary">Add Source {allSources.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allSources.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteSource(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Link {allLinks.length + 1}</label>
                                    <textarea className="form-control" rows="1" value={link} onChange={handleLink}></textarea>
                                </div>
                                <div>
                                    <button type="button" id="addLink" onClick={handleAddNewLink} className="btn btn-secondary">Add Link {allLinks.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ol style={{ 'fontSize':'13px' }}>
                                {
                                    allLinks.map((item, i) => (
                                        <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteLink(i)}>&nbsp;</span></li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </>
                :''
            }
            </form>
    </>
  )
}

export default QA