import React from "react";
import { useState, useEffect } from "react";
import Brands from './Brands';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from  'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const Inventory = () => {

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState('');

    const bold = {
        fontWeight : '600'
    }

    const handleBrand = (e) => {
        setBrand(e.target.value)
    }

    useEffect(() => {
        setLoading(true)
        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify({name : brand})
        })
        .then(response => response.json())
        .then(data => setList(data.results))
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                                "dom": "lifrtp",
                                "order": [[ 10, "asc" ]]
                            })
                        }
                        clearInterval(caller)
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }, [brand]);

    const handleDelete = (e, id, brand, name) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete the ' + brand + ' "' + name + '"?')){
            const endpoint = process.env.REACT_APP_BASE_URL + '/api/delete.php';
            fetch(endpoint, {
                "method": "POST",
                "body": JSON.stringify({id : id})
            })
            .then(response => response.json())
            .then(response => {
                if(response.action === "failed"){
                    toast.error(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                }else{
                    toast.success(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                    list.splice(i, 1);
                    setList(list);
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 2500);
                }
            })
            .catch(err => {
                console.log(err);
            });
        }else{
        }
    }

    return (
        <div >
            <RotatingLines
                strokeColor="rgb(143, 63, 14)"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loading}
            />
            <ToastContainer />
            <div className="form-group mb-3">
                    <label style={bold}>Brands</label>
                    <div className="controls">
                        <select
                            name="brand"
                            id="brand"
                            className="form-select mb-3"
                            onChange={(e) => handleBrand(e)}
                            value={brand}>
                                <option value="">All brands</option>
                                {
                                    Brands.map((item, i) => (
                                        <option value={item} key={i}>
                                                {item}
                                        </option>
                                    ))
                                }
                        </select>
                        <div className="invalid-feedback">The brand field is required </div>
                        <div className="valid-feedback"></div>
                    </div>
                </div>
            {
                loading ? '' :

                list.length > 0 ? 
                <div className="table-responsive">
                    <table id="file_export" className="table table-striped table-bordered display small">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Brand</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Map</th>
                                <th>Phone</th>
                                <th>Description</th>
                                <th>Gallery</th>
                                <th>See more</th>
                                <th>Stars</th>
                                <th>Creation date</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list.map((item, i) => (
                                <tr key={i} >
                                    <td>
                                        <div className="image-dog">
                                            <img width="100" height="auto" src={item.images[0]}/>
                                            <img width="100" height="auto" src={item.images[1]}/>
                                            <img width="100" height="auto" src={item.images[2]}/>
                                        </div>
                                    </td>
                                    <td>{item.brand}</td>
                                    <td style={bold}>{item.name}</td>
                                    <td>{item.address}</td>
                                    <td>
                                    <iframe src={item.map} width="600" height="450"></iframe>
                                    </td>
                                    <td>{item.phone}</td>
                                    <td>{item.description}</td>
                                    <td>{item.gallery}</td>
                                    <td>{item.seemore}</td>
                                    <td>{item.rating}</td>
                                    <td>{item.createdAt}</td>
                                    <td><Link to={"/edit/" + item.id}><button type="button" className="btn btn-info">Edit</button></Link></td>
                                    <td><button type="button" className="btn btn-light"><span className="delete" onClick={(e) => handleDelete(e, item.id, item.brand, item.name)}>&nbsp;</span></button></td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Image</th>
                                <th>Brand</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Map</th>
                                <th>Phone</th>
                                <th>Description</th>
                                <th>Gallery</th>
                                <th>See more</th>
                                <th>Stars</th>
                                <th>Creation date</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
        : 'No result found'
        }
    </div>
    );
}



export default Inventory;