import React from "react";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import Ratings from "../components/Ratings";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Brands from "../components/Brands";


const  Edit = () => {

    const { id } = useParams();
    const [name, setName] = useState('');
    const [brand, setBrand] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [gallery, setGallery] = useState('');
    const [seemore, setSeeMore] = useState('');
    const [rating, setRating] = useState('');
    const [address, setAddress] = useState('');
    const [map, setMap] = useState('');
    
    const bold = {
        fontWeight : '600'
    }

    const handleBrand = event => {
        setBrand(event.target.value);
    }

    const handlePhone = event => {
        setPhone(event.target.value);
    }

    const handleName = event => {
        setName(event.target.value);
    }

    const handleAddress = event => {
        setAddress(event.target.value);
    }

    const handleMap = event => {
        setMap(event.target.value);
    }

    const handleRating = event => {
        setRating(event.target.value);
    }

    const handleSeeMore = event => {
        setSeeMore(event.target.value);
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            id : id,
            brand : brand,
            name : name,
            description : description,
            gallery : gallery,
            phone : phone,
            seemore : seemore,
            rating : rating,
            address : address,
            map : map
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/edit.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                //reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/getInfo.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify({id : id})
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                console.log("Error");
            }else{
                setDescription(response.results.description);
                setGallery(response.results.gallery);
                setName(response.results.name);
                setRating(response.results.rating);
                setBrand(response.results.brand);
                setPhone(response.results.phone);
                setSeeMore(response.results.seemore);
                setAddress(response.results.address);
                setMap(response.results.map);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }, [id]);
 

    const handleDescription = event => {
        const desc = event.target.value;
        setDescription(desc);
            
        if(desc.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleGallery = event => {
        const url = event.target.value;
        setGallery(url);
            
        if(url.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    
    return (
        <>
        <ToastContainer />
        <Header page="Edit"/> 
        {
            <div className="container-fluid small">
                <form style={{ marginTop:'30px' }}>
                    <div className="form-group mb-3">
                        <label style={bold}>Brands<span className="text-danger">*</span></label>
                        <div className="controls">
                            <select
                                name="brand"
                                id="brand"
                                className="form-select mb-3" 
                                onChange={handleBrand} 
                                value={brand}>
                                    <option value="">Select the brand</option>
                                    {
                                        Brands.map((item, i) => (
                                            <option value={item} key={i}>
                                                    {item}
                                            </option>
                                        ))
                                    }
                            </select>
                            <div className="invalid-feedback">The brand field is required </div>
                            <div className="valid-feedback"></div>
                        </div>
                    </div>                   

                    <div className="form-group mb-3">
                        <label style={bold}>Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="name" value={name} onChange={handleName}/>
                        <div className="invalid-feedback">The name field is required</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>Address <span className="text-danger">*</span></label>
                        <textarea rows={2} className="form-control small" value={address} onChange={handleAddress}></textarea>
                        <div className="invalid-feedback">The Address field is required (Min 10 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>Map <span className="text-danger">*</span></label>
                        <textarea rows={1} className="form-control small" value={map} onChange={handleMap}></textarea>
                        <div className="invalid-feedback">The Map field is required (Min 10 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>Description <span className="text-danger">*</span></label>
                        <textarea rows={2} className="form-control small" value={description} onChange={handleDescription}></textarea>
                        <div className="invalid-feedback">The description field is required (Min 10 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>Phone <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="phone" value={phone} onChange={handlePhone}/>
                        <div className="invalid-feedback">The phone field is required (Min 10 characters)</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>Gallery <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="gallery" value={gallery} onChange={handleGallery}/>
                        <div className="invalid-feedback">Provide with a valid gallery Url</div>
                        <div className="valid-feedback"></div>
                    </div>

                    <div className="form-group mb-3">
                        <label style={bold}>See More <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="seemore" value={seemore} onChange={handleSeeMore}/>
                        <div className="invalid-feedback">Provide with a valid See More Url</div>
                        <div className="valid-feedback"></div>
                    </div>

                    {
                    brand === 'hotel' ?

                    <div className="form-group mb-3">
                    <label style={bold}>Stars</label>
                    <div className="controls">
                        <select
                            name="rating"
                            id="rating"
                            className="form-select mb-3" 
                            onChange={handleRating} 
                            value={rating}>
                                <option value="">Select the star</option>
                                {
                                    Ratings.map((item, i) => (
                                        <option value={item} key={i}>
                                                {item}
                                        </option>
                                    ))
                                }
                        </select>
                        <div className="invalid-feedback">The stars field is required </div>
                        <div className="valid-feedback"></div>
                    </div>
                    </div> 
                    : ''
                    }
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                    </form>
            </div>
        }
        </>
     );
}
 
export default Edit;