import React from "react";
import Header from "../components/Header";
import Inventory from "../components/Inventory";


const Listing = () => {
    return ( 
        <>
        <Header page="listing"/>
        <Inventory />
        </>
     );
}
export default Listing;